import React, { useEffect, useState } from "react";
import { Divider, Typography, TextField, Button } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [new_username, setNewUsername] = useState("");
    const [new_title, setNewTitle] = useState("");
    const [new_content, setNewContent] = useState("");


    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                "https://ryao28_workers_project.ryao28.workers.dev/"
            )
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);

    const handleSubmit = () => {

        fetch("https://ryao28_workers_project.ryao28.workers.dev/", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify([{
                "title": new_title,
                "username": new_username,
                "content": new_content
            }])
        });
        alert("Added new post! Please wait a bit for the new changes to reflect.")
    }

    const handleUsernameChange = (e) => {
        setNewUsername(e.target.value)
    }

    const handleTitleChange = (e) => {
        setNewTitle(e.target.value)
    }

    const handleContentChange = (e) => {
        setNewContent(e.target.value)
    }

    return (
        <div style={{ textAlign: 'center', justifyContent: 'center', paddingRight: 400, paddingLeft: 400 }}>
            <h1>Your Feed</h1>
            <div>
                <TextField  id="helllo" style={{width: 500, paddingBottom: 30}} label="Username" variant="outlined" value={new_username} onChange={handleUsernameChange} />
                <br />
                <TextField style={{width: 500}} label="Title" variant="outlined" value={new_title} onChange={handleTitleChange} />
                <br />
                <TextField style={{width: 500}} label="Content" variant="outlined" value={new_content} onChange={handleContentChange} />
                <br />
                <br />
                <Button variant="contained" color="primary" type="reset" onClick={() => handleSubmit()}>
                    Add Post
                </Button>
            </div>
            <br />

            {posts.map((post) => (
                <Card variant="outlined" style={{}}>
                    <CardContent style={{ justifyContent: 'center' }}>
                        <Typography variant="h5" style={{ justifyContent: 'center' }}>
                            {post.title}
                        </Typography>
                        <Typography gutterBottom variant="h7" style={{ paddingBottom: 5, fontStyle: 'italic' }}>
                            @{post.username}
                        </Typography>
                        <Divider />

                        <Typography variant="body2" style={{ paddingTop: 30 }}>
                            {post.content}
                        </Typography>
                    </CardContent>

                </Card>
            ))
            }
        </div >
    );
};

export default Posts;